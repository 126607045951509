import img3 from "../../../../Assets/Photos/img3.jpeg";
import img4 from "../../../../Assets/Photos/img4.jpeg";
import img5 from "../../../../Assets/Photos/img5.jpeg";
import img6 from "../../../../Assets/Photos/img6.jpeg";

import img9 from "../../../../Assets/Photos/img9.jpeg";
import img10 from "../../../../Assets/Photos/img10.jpeg";
import img11 from "../../../../Assets/Photos/img11.jpeg";
import img12 from "../../../../Assets/Photos/img12.jpeg";
import kid1 from "../../../../Assets/kids/Kid1.jpeg";
import kid2 from "../../../../Assets/kids/kid2.jpeg";
import kid3 from "../../../../Assets/kids/kid3.jpeg";
import kid4 from "../../../../Assets/kids/kid4.jpeg";
export default [
  kid1,
  img3,
  img4,
  kid2,
  kid3,
  img5,
  kid4,
  img6,
  img9,
  img10,
  img11,
];
